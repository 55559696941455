.user-container{
  width: 100%;
  height: calc(100vh - 100px);
}

.user-sub-container{
  width: 100%;
  height: 50px;
  box-shadow: 0 1px 2px 0 #272727;
  display: flex;
  z-index: 9999;
}

.users-list{
  height: inherit;
  width: 700px;  
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.custom{
  font-family: var(--family);
  border: none;
  background: var(--header);
  height: inherit;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 500;

}

.custom:hover{
 border-bottom: 2px solid var(--brand-color);
 color: var(--brand-color);
}

.activeButton{
  border-bottom: 2px solid var(--brand-color);
  color: var(--brand-color);
}

.user-list{
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create{
  margin-right: 20px;
}


.chart_data{
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
}
iframe{
  border: none;
}
.groww{
  width: inherit;
  height: 800px;
  display: flex;
  flex-direction: row;
}

.sensibull{
  width: inherit;
  height: inherit;
  height: 800px;
}


.tickTable .ant-table-row {
  height: 10px !important; /* Set the desired row height */
}

.tickTable .ant-table-cell {
  padding: 4px 8px; /* Adjust cell padding for tighter spacing */
}
.tickTable .ant-table-title{
  background-color: #181818;
  color: white;
  border: 1px solid #232325;
}