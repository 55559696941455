.live-container{
  width: 100%;
  height: calc(100vh - 100px);
}

.live-sub-container{
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 2px 0 #272727;
  display: flex;
}

.pl-section{
  width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
}

.pl{
  margin-left: 15px;
}

.loss{
  color: var(--loss);
}

.profit{
color: var(--profit);
}
.normal{
  color: #4988ee;
}
.actions{
  width: 550px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-around;
}



.table{
  min-height: calc(100vh - 220px);
  scrollbar-width: none !important;
  font-family: var(--family) !important;
  min-width: calc(100% - 300px);
  margin:50px
}

.ant-table-cell{
  background-color: var(--header) !important;
  color: white !important;
  border-bottom: 1px solid #232325 !important;
  /* text-align: center !important; */
  border-top: 1px solid #232325;
  border-left: 1px solid #232325;
  border-right: 1px solid #232325;
  font-family: var(--family) !important;
  cursor: pointer;
}

.ant-empty-description{
  color: white !important;
}

