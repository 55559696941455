
.login-container{
    width: 1280px;
    height: calc(100vh - 10px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1970px;
    overflow: auto;
    display: flex;
    justify-content: center;
}

.login-modal{
  width: 400px;
  height: 350px;
  background-color: var(--header);
  margin-top: 150px;
  border-radius: 20px;
}

.login-header{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: 35px;
}


.company-name{
  margin-left: 10px;
}


.login-form{
  width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;

}

.login-text{
  width: 300px;
}

.social-login{
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 50px;
}