:root {
  --primary-color: #111;
  --header:#181818;
  --brand-color:#d4603b;
  --text-color:white;
  --danger-color:#df514c;
  --loss:#e25f5b;
  --profit:#5b9a5d;
  --family: 'Open Sans';
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
  color: var(--text-color)
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
