.order-container{
  width: 100%;
  height: calc(100vh - 100px);
}

.order-sub-container{
  width: 100%;
  height: 50px;
  box-shadow: 0 1px 2px 0 #272727;
  display: flex;
  z-index: 1;
  background-color: var(--header);

}

.users-list{
  height: inherit;
  width: 700px;  
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.custom{
  font-family: var(--family);
  border: none;
  background: var(--header);
  height: inherit;
  color: white;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 500;

}

.custom:hover{
 border-bottom: 2px solid var(--brand-color);
 color: var(--brand-color);
}

.activeButton{
  border-bottom: 2px solid var(--brand-color);
  color: var(--brand-color);
}

.order-list{
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create{
  margin-right: 20px;
}

.funds{
  width: 500px;
  height: 200px;
  background-color: var(--header);
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #232325;
}

.keys{
  width: 150px;
  height: 150px;
  background-color: var(--header);
  margin-left: 30px;
  color: #8a8a8a;

}

.transp{
  color: transparent;
}

.price{
  color: #4987ee;

}

/* .kiteFrame{
  width: 100%;
  height: 1020px;
  position: relative;
} */

.pulseFrame{
  width: 100%;
  height: 1020px;
  position: relative;
}


iframe{
  width: inherit;
  height: inherit;
}

.header-hide-left{
    width: 210px;
    height: 50px;
    background-color: #ffff;
    position: absolute;
    top: 10px;
    left: 10px;
}

.header-hide-right{
  width: 260px;
  height: 50px;
  background-color: #ffff;
  position: absolute;
  top: 10px;
  right: 10px
}

.footer-hide-pulse{
  width: 100%;
  height: 156px;
  background-color: var(--header);
  position: absolute;
  bottom: 44px;
}

.pulse{
  position: absolute;
  top: -80px;
}