
.header{
  width: 100vw;
  height: 45px;
  background-color: var(--header);
  position: sticky;
  box-shadow: 0 1px 5px 0 #0d0d0d;
  top: 0;
  z-index: 9;
  font-size: 0.7rem;
 
  
}

.header-nav{
  width: 1275px;
  height: inherit;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}


.header-section{
  width: 735px;
  height: inherit;
  display: flex;
  align-items: center;
}

.header-section2{
  width: 535px;
  height: inherit;
  display: flex;
  align-items: center;
}

.nav{
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}


.content{
  width: 1280px;
  height: calc(100vh - 45px);
  background-color: var(--header);
  margin-left: auto;
  margin-right: auto;
  max-width: 1970px;
  overflow: auto;
}

.box{
  width: 500px;
  height: 200px;
  background-color: beige;
  margin: 10px;
}

.name-section{
  width: inherit;
  height: 70px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 1.5rem;
  border-bottom: 0.5px solid rgb(44, 41, 41);
}



img{
  width: auto;
  height: 14px;
  margin-left: 20px;
}

.nav-items{
  cursor: pointer;
}

.active{
  color: var(--brand-color);
  font-weight: 700;
}